const AA_QUIZ = 'aa_quiz'
const ART_FOR_YOUR_STYLE_QUIZ = 'art_for_your_style_quiz'
const ARTIST = 'whysell_artist_signup'
const AUTH_REGISTRATION = 'auth_registration'
const COLLECTOR = 'collectors_generic_signup_lander'
const EASEL_FOOTER = 'easel_footer'
const NFT = 'nft_signup'
const TRADE = 'trade_signup_lander'
const GUEST_CHECKOUT = 'guest_checkout_marketing_sub'
const TRADE_PAGE = 'trade_page'
const TRADE_PAGE_CONTACT_US_FORM = 'trade_page_contact_us_form'
const FB_REGISTRATION = 'fb_registration'
const LGTV_SIGNUP = 'lgtv_signup'
const SUMMER_SALE_SIGNUP = 'summer_sale_signup_lander'
const HOLIDAY_SALE_SIGNUP = 'holiday_sale_signup_lander'
const FEBRUARY_SALE_SIGNUP = 'february_sale_signup_lander'
const MAO_START_EMAIL_SUBMITTED = 'mao_start_email_submitted'

const REQUEST_SOURCE = {
  AA_QUIZ,
  ART_FOR_YOUR_STYLE_QUIZ,
  ARTIST,
  AUTH_REGISTRATION,
  COLLECTOR,
  EASEL_FOOTER,
  NFT,
  TRADE,
  TRADE_PAGE,
  TRADE_PAGE_CONTACT_US_FORM,
  GUEST_CHECKOUT,
  FB_REGISTRATION,
  LGTV_SIGNUP,
  SUMMER_SALE_SIGNUP,
  HOLIDAY_SALE_SIGNUP,
  FEBRUARY_SALE_SIGNUP,
  MAO_START_EMAIL_SUBMITTED,
}

module.exports = { REQUEST_SOURCE }
