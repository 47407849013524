import { createSelector } from 'reselect'
import { User } from 'types/User'
import { Page } from 'types/Page'
import { getUser } from './helpers'
import { getPage } from '../page'

export const getABTest = createSelector([getUser], (user: User): string => user.abTest || '')

export const getHomepageExperience = createSelector(
  [getPage],
  (page: Page): string => page.experience || 'Legacy'
)

// get AB Test assignments values, as in which value (or `group`) has been assigned for each AB test
export const getABTestValues = createSelector(
  [getABTest],
  (abTest: string): Record<string, any> => {
    const parseTest = {}
    abTest.split(',').forEach((test) => {
      const [key, value] = test.split('.')

      if (key && value) {
        parseTest[key] = value
      }
    })
    return parseTest || {}
  }
)
